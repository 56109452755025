<!-- NOT USED -->
<template>
    <div class="container flex flex-col">
        <div
            class="mx-auto w-full max-w-960 mt-8 mb-8 p-4 bg-white-pure l-box-shadow rounded"
            v-if="!loading"
        >
            <div class="flex flex-row w-full">
                <v-form v-model="isValid" ref="accountSettingsForm">
                    <h1
                        class="text-black mr-2 mb-8 text-xl lg:text-2xl text-center"
                        data-cy="accountSettingsTitle"
                    >
                        Account Settings
                    </h1>

                    <div class="grid grid-cols-6 gap-x-4 w-full a-settings mb-8">
                        <!-- TODO: add ProfilePicture back when functionality for saving the avatar is added -->
                        <!--                        <ProfilePicture-->
                        <!--                            :user="accountSettingsForm"-->
                        <!--                            class="col-span-6 xl:col-span-2 float-left"-->
                        <!--                            @updated-profile="updatedProfile"-->
                        <!--                        />-->
                        <div class="col-start-2 col-span-6 xl:col-span-4 xl:col-start-2">
                            <v-text-field
                                hide-details
                                v-model="user.email"
                                placeholder="Email"
                                disabled
                                label="Email"
                            ></v-text-field>
                        </div>
                        <UserProfile
                            v-model="accountSettingsForm"
                            ref="userProfileForm"
                            class="col-start-2 col-span-6 xl:col-span-4 xl:col-start-2"
                        />
                    </div>
                    <div class="flex flex-row justify-center mt-8">
                        <v-btn depressed class="mr-4 w-40" @click="goBack()">Cancel</v-btn>
                        <v-btn
                            depressed
                            class="v-btn--flat inline bg-green-light w-40"
                            color="success"
                            :disabled="saving"
                            @click="validate()"
                            >Save</v-btn
                        >
                    </div>
                </v-form>
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapState } from 'vuex'
import ProfilePicture from '@/components/account-settings/ProfilePicture'
import UserProfile from '@/components/shared/UserProfile'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HistoryFns from '@/components/shared/mixins/historyFns'
import Loader from '@/assets/loader.svg'
import { userService } from '@/services/user.service'

const { useState } = createNamespacedHelpers('app')

export default {
    name: 'AccountSettings',
    mixins: [MessageDialog, HistoryFns],
    components: { ProfilePicture, UserProfile, Loader },
    data() {
        return {
            saving: false,
            isValid: true,
            user: {},
            defaultRoute: {
                name: 'dashboard'
            }
        }
    },
    methods: {
        validate() {
            Promise.all([
                this.$refs.accountSettingsForm.validate(),
                this.$refs.userProfileForm.validate()
            ])
                .then(async (res) => {
                    const user = { ...this.accountSettingsForm }
                    user.phone = user?.phone?.phone || ''
                    try {
                        const updatedUser = await userService.updateUserByClientId(
                            this.user.clientId,
                            this.user
                        )
                        this.$store.dispatch('setLoggedInUser', updatedUser)
                        this.user = updatedUser
                        this.message = 'Settings have been saved.'
                        this.type = 'success'
                    } catch (err) {
                        this.message = 'Error saving your settings'
                        this.type = 'error'
                    } finally {
                        this.saving = false
                        this.showMessage({ duration: 5000 })
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                })
        }
    },
    computed: {
        ...mapState({
            routeFrom: (state) => state.app.routeFrom
        }),
        accountSettingsForm() {
            return this.user
                ? {
                      firstName: this.user.firstName,
                      lastName: this.user.lastName,
                      phone: this.user.phone
                  }
                : {}
        }
    },
    setup() {
        const { loggedInUser } = useState(['loggedInUser'])

        const state = reactive({
            user: {},
            loading: true,
            saving: false,
            isValid: true,
            defaultRoute: {
                name: 'dashboard'
            }
        })

        onMounted(async () => {
            const user = await userService.getUser(loggedInUser.value?.userId)
            state.user = { ...user }
            state.loading = false
        })

        return {
            ...toRefs(state),
            loggedInUser
        }
    }
}
</script>
